import { useEffect } from 'react';
import { useRequest } from 'websdk';

export default function (params) {
  const { exec, isRequestSuccessed } = useRequest({
    url: `/desktopadmins/v2/desktopapp`,
    name: 'DELETE_DESKTOP',
    method: 'DELETE',
  });

  useEffect(() => {
    if (params) {
      console.log('useDeleteDesktopApp useEffect', params);
      exec({ url: `/desktopadmins/v2/desktopapp`, data: params });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return isRequestSuccessed;
}
