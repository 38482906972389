import { useEffect } from 'react';
import { useRequest } from 'websdk';

export default (function (newDesktopApp) {
  const { exec, isRequestSuccessed } = useRequest({
    url: '/desktopadmins/v2/desktopapps',
    name: 'POST_APP',
    method: 'POST',
  });

  useEffect(() => {
    if (newDesktopApp) {
      console.log('  2server-usePostApp :', newDesktopApp);
      exec({ url: '/desktopadmins/v2/desktopapps', data: newDesktopApp });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDesktopApp]);

  return isRequestSuccessed;
});
