import Mock from 'mockjs';
import qs from 'qs';
import { test1, test2, test3, test4 } from '@/img';

export default Mock.mock(RegExp(/\/desktopadmins\/v2\/apps(|\*)/), 'get', options => {
  console.log('     3mock---apps-list:', options);
  let res = Mock.mock({
    rst: 200,
    data: [
      {
        id: 'id-1',
        name: 'App1',
        icon: `url(${test1})`,
      },
      {
        id: 'id-2',
        name: 'App2',
        icon: `url(${test2})`,
      },
      {
        id: 'id-3',
        name: 'App3',
        icon: `url(${test3})`,
      },
      {
        id: 'id-4',
        name: 'App4',
        icon: `url(${test4})`,
      },
      {
        id: 'id-5',
        name: 'App5',
        icon: `url(${test2})`,
      },
      {
        id: 'id-6',
        name: 'App6',
        icon: `url(${test2})`,
      },
      {
        id: 'id-7',
        name: 'App7',
        icon: `url(${test3})`,
      },
      {
        id: 'id-8',
        name: 'App8',
        icon: `url(${test2})`,
      },
      {
        id: 'id-9',
        name: 'App9',
        icon: `url(${test4})`,
      },
      {
        id: 'id-10',
        name: 'App10',
        icon: `url(${test3})`,
      },
    ],

    msg: 'success',
  });
  const search = qs.parse(options.url.split('?')[1], { ignoreQueryPrefix: true });

  let count = res.data.length;

  //example:renturn data by options
  if (search.page && search.page_size) {
    res.data = res.data.slice((search.page - 1) * search.page_size, search.page * search.page_size);
  }
  if (search.id) count = 1;
  let data = { apps: res.data, total_count: count };
  res.data = data;
  return res;
});
