import { useEffect } from 'react';
import { useRequest } from 'websdk';

export default (function (desktopApp) {
  const { exec, isRequestSuccessed } = useRequest({
    url: '/desktopadmins/v2/desktopapp',
    name: 'PUT_DESKTOP_APP',
    method: 'PUT',
  });

  useEffect(() => {
    if (desktopApp) {
      console.log('  2server-usePutDesktopApp :', desktopApp);
      exec({ url: '/desktopadmins/v2/desktopapp', data: desktopApp });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktopApp]);

  return isRequestSuccessed;
});
