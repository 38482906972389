import React, { createContext /*useEffect, useState, useCallback, useContext*/ } from 'react';
import * as serviceWorker from '@/serviceWorker';
import '@/assets/css/app.css';
import '@/assets/css/spacing.css';
import {
  EuiHeader,
  EuiHeaderSectionItem,
  EuiHeaderLinks,
  EuiHeaderLink,
  EuiProvider,
  EuiPageTemplate,
  EuiSelect,
} from '@elastic/eui';
//import Sidebar from './sidebar';
import { useLogout, useLoginInfo, useRefreshToken, Toast, setLang } from 'websdk';
//import { useLanguages, useGetSelfProjectTransls } from '@/server';
import headerDefaultTransls from './headerDefaultTransls';

serviceWorker.unregister();

const translsContext = createContext();
const updateHeaderLangsContext = createContext();

/*const useLayoutTransls = defaultTransls => {
  const [serverTransls, getServerTransls] = useContext(translsContext);
  const [transls, setPageTransls] = useState(defaultTransls);

  useEffect(() => {
    getServerTransls(Object.keys(defaultTransls));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getServerTransls]);

  useEffect(() => {
    if (JSON.stringify(serverTransls) !== '{}') {
      let newPageTransls = { ...transls };
      let hasChange = false;
      Object.keys(defaultTransls).forEach(stringKey => {
        if (serverTransls[stringKey]) {
          newPageTransls[stringKey] = serverTransls[stringKey];
          hasChange = true;
        }
      });
      if (hasChange) setPageTransls(newPageTransls);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverTransls]);

  return transls;
};*/

//export { updateHeaderLangsContext, useLayoutTransls };

//let requestedStringKeys = [];

function Layout(props) {
  Object.keys(headerDefaultTransls).forEach(key => (headerDefaultTransls[key] = key));
  if (localStorage.langCode) setLang(localStorage.langCode);
  else {
    setLang('en-US');
  }

  const logout = useLogout('/login.html');
  const info = useLoginInfo();

  useRefreshToken();

  let options = [];
  let langIdSelected = '';
  /*const [transls, updateTransls] = useGetSelfProjectTransls(true);

  const [langIdSelected, setLangIdSelected] = useState('');

  useEffect(() => {
    if (langIdSelected && requestedStringKeys.length > 0)
      updateTransls({ lang_id: langIdSelected, string_keys: requestedStringKeys.join(',') });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langIdSelected]);

  const [languagesList, updateLanguagesList] = useLanguages();

  const languages = languagesList.languages;
  let options = languages;
  if (languages) {
    options = languages.map(lang => {
      return { value: lang.uuid, text: `${lang.name}/${lang.code}` };
    });
  }

  const getTransls = useCallback(
    newStringKeys => {
      //取newStringKeys相对requestedStringKeys没有的stringKey
      newStringKeys = newStringKeys.filter(item => !new Set(requestedStringKeys).has(item));

      if (newStringKeys.length > 0) {
        requestedStringKeys = Array.from(new Set([...requestedStringKeys, ...newStringKeys]));
        if (langIdSelected)
          updateTransls({ lang_id: langIdSelected, string_keys: newStringKeys.join(',') });
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [currentPageAllTransls, setCurrentPageAllTransls] = useState(headerDefaultTransls);

  useEffect(() => {
    getTransls(Object.keys(headerDefaultTransls));
  }, [getTransls]);

  useEffect(() => {
    if (JSON.stringify(transls) !== '{}')
      setCurrentPageAllTransls({ ...currentPageAllTransls, ...transls });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transls]);

  const onChange = e => {
    setLangIdSelected(e.target.value);
    languagesList.languages.forEach(lang => {
      if (lang.uuid === e.target.value) {
        localStorage.langCode = lang.code;
        localStorage.langId = lang.uuid;
        setLang(lang.code);
      }
    });
  };

  useEffect(() => {
    const { languages, total_count } = languagesList;
    if (total_count !== 0 && languages) {
      let hasLang = false;

      languages.forEach(lang => {
        if (lang.uuid === localStorage.langId) {
          hasLang = true;
          setLangIdSelected(lang.uuid);
        }
      });
      if (!hasLang) {
        setLangIdSelected(languages[0].uuid);
        setLang(languages[0].code);
        localStorage.langCode = languages[0].code;
        localStorage.langId = languages[0].uuid;
      }
    }
  }, [languagesList]);*/

  return (
    <EuiProvider colorMode="light">
      <EuiHeader theme="dark">
        <EuiHeaderSectionItem border="right" style={{ color: 'white' }}>
          桌面应用管理{/*currentPageAllTransls.APP_NAME*/}
        </EuiHeaderSectionItem>

        <EuiHeaderSectionItem>
          <EuiSelect
            options={options}
            value={langIdSelected}
            //onChange={e => onChange(e)}
            aria-label="Use aria labels when no actual label is in use"
          />
          <EuiHeaderLinks>
            <EuiHeaderLink>{info.login}</EuiHeaderLink>
            <EuiHeaderLink onClick={logout}>登出{/*currentPageAllTransls.LOG_OUT*/}</EuiHeaderLink>
          </EuiHeaderLinks>
        </EuiHeaderSectionItem>
      </EuiHeader>
      <updateHeaderLangsContext.Provider /*value={updateLanguagesList}*/>
        <translsContext.Provider /*value={[transls, getTransls]}*/>
          <EuiPageTemplate id="content" /*pageSideBar={<Sidebar />}*/>
            {props.children}
          </EuiPageTemplate>
        </translsContext.Provider>
      </updateHeaderLangsContext.Provider>
      <Toast />
    </EuiProvider>
  );
}

export default Layout;
