import { useRequest } from 'websdk';

export default (function (params) {
  if (!params || params === true) {
    const { data, exec } = useRequest({
      url: '/desktopadmins/v2/desktopapps',
      name: 'GET_DESKTOP_APPS',
      params,
    });
    return [data || [], exec];
  } else {
    const { data, exec } = useRequest({
      url: ``,
      name: 'GET_DESKTOP_APPS',
      manual: false,
    });
    const newExec = params => {
      if (params) {
        console.log('  2server--desktopapp-list-params:', params);
        let url_params = Object.keys(params)
          .map(key => `${key}=${params[key]}`)
          .join('&');
        if (url_params) exec(`/desktopadmins/v2/desktopapps?${url_params}`);
      } else {
        exec(`/desktopadmins/v2/desktopapps`);
      }
    };

    return [data || {}, newExec];
  }
});
