import Mock from 'mockjs';
import uuid from 'react-uuid';

export default Mock.mock(RegExp(/\/desktopadmins\/v2\/desktopapps/), 'post', options => {
  console.log('     3mock---post desktopapps request', options.body);
  return {
    rst: 200,
    data: uuid(),
    msg: 'success',
  };
});
