import React, { createContext, useState, useEffect } from 'react';
import useResizeObserver from 'use-resize-observer';
import ReactDOM from 'react-dom';
import Layout from '@/layout';
import { left, right } from '@/img';
import {
  useApps,
  useDesktopApps,
  usePostDesktopApp,
  useDeleteDesktopApp,
  usePutDesktopApp,
} from '@/server';
import moment from 'moment';
import {
  EuiDragDropContext,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonIcon,
  EuiIcon,
  EuiPanel,
  EuiDraggable,
  EuiDroppable,
  //euiDragDropCopy,
  htmlIdGenerator,
  //euiDragDropReorder,
  //euiDragDropMove,
} from '@elastic/eui';

const DataContext = createContext();

const kTotalAppOffset = 1000;
const kTotalApp = 1;
const kDesktopApp = 2;
const kEachDesktopAppWidth = 220;
const kEachDesktopAppHeight = 140;
const kEachAllAppWidth = 120;
const kEachAllAppHeight = 75;
const kPanelTopSpace = 150;
const kMargin = 0;

const makeId = htmlIdGenerator();
const makeList = (number, start = 1) =>
  Array.from({ length: number }, (v, k) => k + start).map(el => {
    return {
      content: `Item ${el}`,
      id: makeId(),
    };
  });

function Main() {
  const [isItemRemovable /*, setIsItemRemovable*/] = useState(false);
  const [appList, setAppList] = useState(makeList(0));
  const [desktopAppList, setDesktopAppList] = useState(makeList(0));
  const totalLists = {
    DROPPABLE_AREA_ALLAPP_0: appList,
    DROPPABLE_AREA_ALLAPP_1: appList,
    DROPPABLE_AREA_COPY_DESK_0: desktopAppList,
    DROPPABLE_AREA_COPY_DESK_1: desktopAppList,
    DROPPABLE_AREA_COPY_DESK_2: desktopAppList,
    DROPPABLE_AREA_COPY_DESK_3: desktopAppList,
    DROPPABLE_AREA_COPY_DESK_4: desktopAppList,
    DROPPABLE_AREA_COPY_DESK_5: desktopAppList,
  };
  const actions = {
    DROPPABLE_AREA_ALLAPP_0: setAppList,
    DROPPABLE_AREA_ALLAPP_1: setAppList,
    DROPPABLE_AREA_COPY_DESK_0: setDesktopAppList,
    DROPPABLE_AREA_COPY_DESK_1: setDesktopAppList,
    DROPPABLE_AREA_COPY_DESK_2: setDesktopAppList,
    DROPPABLE_AREA_COPY_DESK_3: setDesktopAppList,
    DROPPABLE_AREA_COPY_DESK_4: setDesktopAppList,
    DROPPABLE_AREA_COPY_DESK_5: setDesktopAppList,
  };
  const remove = (droppableId, index) => {
    const list = Array.from(totalLists[droppableId]);
    list.splice(index, 1);

    actions[droppableId](list);
  };

  const onDragEnd = ({ source, destination }) => {
    console.log('-page-1onDragEnd-', source, destination);
    if (source && destination) {
      if (
        source.droppableId.indexOf('DROPPABLE_AREA_ALLAPP') === -1 &&
        destination.droppableId.indexOf('DROPPABLE_AREA_ALLAPP') === -1
      ) {
        clearDragShowContents(source.index, destination.index);
        endDrag(source.index, destination.index, kDesktopApp);
      } else {
        endDrag(source.index - kTotalAppOffset, destination.index, kTotalApp);
      }
    } else if (!destination && source.droppableId.indexOf('DROPPABLE_AREA_ALLAPP') === -1) {
      remove(source.droppableId, source.index);
    }
  };

  const [desktopAppColmn, setDesktopAppColmn] = useState(4);
  const [rightPanelHeight, setRightPanelHeight] = useState(
    desktopAppColmn * kEachDesktopAppHeight + kPanelTopSpace
  );
  const [isVisible, setIsVisible] = useState(false);
  const [pageIndex /*, setPageIndex*/] = useState(0);
  const [pageSize /*, setPageSize*/] = useState(10);
  const [desktopAppPageIndex /*, setDesktopAppPageIndex*/] = useState(0);
  const [desktopAppPageSize /*, setDesktopAppPageSize*/] = useState(10);
  const [appIds, setAppIds] = useState([]);
  const [appNames, setAppNames] = useState([]);
  const [appUrls, setAppUrls] = useState([]);
  const [appIcons, setAppIcons] = useState([]);
  const [appDeskExis, setAppDeskExis] = useState([]);
  const [desktopAppIds, setDesktopAppIds] = useState([]);
  const [desktopAppUrls, setDesktopAppUrls] = useState([]);
  const [desktopAppNames, setDeskAppNames] = useState([]);
  const [desktopAppIcons, setDesktopAppIcons] = useState([]);
  const [postAppParams, setPostParam] = useState(null);
  const isPostSuccessed = usePostDesktopApp(postAppParams);
  const [deleteDesktopApp, setDeleteDesktopApp] = useState(null);
  const isDeleteSuccessed = useDeleteDesktopApp(deleteDesktopApp);
  const [putDesktopAppParams, setPutDesktopAppParam] = useState(null);
  const isPutSuccessed = usePutDesktopApp(putDesktopAppParams);
  const { ref, width: refWidth, height: refHeight } = useResizeObserver();
  const [allAppColmn, setAllAppColmn] = useState(2);
  const [leftPanelHeight, setLeftPanelHeight] = useState(
    allAppColmn * kEachAllAppHeight + kPanelTopSpace
  );
  const { ref: leftRef, width: leftRefWidth, height: leftRefHeight } = useResizeObserver();
  const [heightSync, setHeightSync] = useState(
    leftPanelHeight < rightPanelHeight ? rightPanelHeight : leftPanelHeight
  );

  const [params, setParam] = useState({
    page: 1,
    page_size: 10,
    order_by: 1,
    desc: true,
  });
  const [data, updateAppList] = useApps(params);

  const [desktopAppParams, setDesktopAppParam] = useState({
    page: 1,
    page_size: 10,
    order_by: 1,
    desc: true,
  });
  const [desktopAppData, updateDesktopAppList] = useDesktopApps(desktopAppParams);

  const updateVisible = () => {
    let leftpanel = document.getElementById('leftPanel');
    let rightPanel = document.getElementById('rightPanel');
    if (isVisible) {
      leftpanel.style.display = 'none'; // 隐藏左侧
      rightPanel.style.width = '100%';
      document.getElementById('switchButton').style.backgroundImage = `url(${right})`;
      setIsVisible(false);
      //console.log('-----page--:', document.getElementById('allPanel').style.width);
    } else {
      //展开
      leftpanel.style.display = 'inline-block'; //
      leftpanel.style.width = '30%';
      rightPanel.style.display = 'inline-block';
      rightPanel.style.width = '70%';
      setIsVisible(true);
      document.getElementById('switchButton').style.backgroundImage = `url(${left})`;
    }
    let newColmn = Math.floor((rightPanel.offsetWidth - kMargin * 2) / kEachDesktopAppWidth);
    //console.log('----rightPanel.offsetWidth:', rightPanel.offsetWidth, newColmn);
    setDesktopAppColmn(1 < newColmn ? newColmn : 1);
  };

  useEffect(() => {
    if (leftPanelHeight !== undefined && rightPanelHeight !== undefined) {
      let heightSync = leftPanelHeight < rightPanelHeight ? rightPanelHeight : leftPanelHeight;
      setHeightSync(heightSync);
    }
  }, [leftPanelHeight, rightPanelHeight]);

  useEffect(() => {
    if (refWidth !== undefined) {
      let newColmn = Math.floor((refWidth - kMargin * 2) / kEachDesktopAppWidth);
      if (desktopAppIds.length !== 0)
        setRightPanelHeight(
          Math.ceil(desktopAppIds.length / newColmn) * kEachDesktopAppHeight + kPanelTopSpace
        );
      setDesktopAppColmn(1 < newColmn ? newColmn : 1);
      setDesktopAppList(makeList(Math.ceil(desktopAppIds.length / newColmn)));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refWidth, refHeight, desktopAppIds]);

  useEffect(() => {
    if (refWidth !== undefined) {
      let newColmn =
        Math.floor(leftRefWidth / kEachAllAppWidth) > 1
          ? Math.floor(leftRefWidth / kEachAllAppWidth)
          : 1;
      setAllAppColmn(newColmn);
      if (appIds.length !== 0)
        setLeftPanelHeight(
          Math.ceil(appIds.length / newColmn) * kEachAllAppHeight + kPanelTopSpace
        );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftRefWidth, leftRefHeight, appIds]);

  useEffect(() => {
    let newAppDeskExis = [];
    for (var i = 0; i < appIds.length; i++) {
      let exis = false;
      for (var j = 0; j < desktopAppIds.length; j++) {
        if (desktopAppIds[j] === appIds[i]) {
          exis = true;
          break;
        }
      }
      newAppDeskExis.push(exis);
    }
    setAppDeskExis(newAppDeskExis);
  }, [desktopAppIds, appIds]);

  useEffect(() => {
    const { apps, total_count } = data;
    if (total_count !== 0 && apps) {
      let newIds = [];
      let newNames = [];
      let newUrls = [];
      let newIcons = [];
      for (var i = 0; i < total_count; i++) {
        newIds.push(apps[i].id);
        newNames.push(apps[i].name);
        newUrls.push(apps[i].url);
        newIcons.push(apps[i].icon);
      }
      setAppIds(newIds);
      setAppNames(newNames);
      setAppUrls(newUrls);
      setAppIcons(newIcons);
      setAppList(makeList(total_count));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const { apps, total_count } = desktopAppData;
    if (total_count === 0) {
      setDesktopAppIds([]);
      setDesktopAppUrls([]);
      setDeskAppNames([]);
      setDesktopAppIcons([]);
      setDesktopAppList([]);
    } else if (total_count !== 0 && apps) {
      let newIds = [];
      let newUrls = [];
      let newNames = [];
      let newIcons = [];
      for (var i = 0; i < total_count; i++) {
        newIds.push(apps[i].id);
        newUrls.push(apps[i].url);
        newNames.push(apps[i].name);
        newIcons.push(apps[i].icon);
      }
      setDesktopAppIds(newIds);
      setDesktopAppUrls(newUrls);
      setDeskAppNames(newNames);
      setDesktopAppIcons(newIcons);
      setDesktopAppList(makeList(Math.ceil(total_count / desktopAppColmn)));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktopAppData]);

  useEffect(() => {
    let newParam = {
      page: pageIndex + 1,
      page_count: pageSize,
    };
    setParam(newParam);
    updateAppList(newParam);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize]);

  useEffect(() => {
    let newParam = {
      page: desktopAppPageIndex + 1,
      page_count: desktopAppPageSize,
    };
    setDesktopAppParam(newParam);
    updateDesktopAppList(newParam);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktopAppPageIndex, desktopAppPageSize, isPostSuccessed, isDeleteSuccessed, isPutSuccessed]);

  //拖拽结束的事件
  const endDrag = (sourceIndex, destinationIndex, type) => {
    if (type === kTotalApp) {
      //console.log('---page--endDrag--kTotalApp--destinationIndex:', destinationIndex);
      let newParams = {
        id: appIds[sourceIndex],
        index: destinationIndex,
      };
      setPostParam(newParams);
      return true;
    } else if (type === kDesktopApp) {
      if (sourceIndex === undefined || sourceIndex < 0 || sourceIndex === destinationIndex) {
        return false;
      }
      let newParams = {
        id:
          desktopAppIds.length <= sourceIndex
            ? desktopAppIds[desktopAppIds.length - 1]
            : desktopAppIds[sourceIndex],
        index:
          desktopAppIds.length <= destinationIndex ? desktopAppIds.length - 1 : destinationIndex,
      };
      setPutDesktopAppParam(newParams);
      return true;
    }
  };

  const clearDragShowContents = (sourceIndex, destinationIndex) => {
    let newDesktopAppNames = desktopAppNames;
    let newDesktopAppIcons = desktopAppIcons;
    newDesktopAppNames[sourceIndex] = '';
    newDesktopAppNames[destinationIndex] = '';
    newDesktopAppIcons[sourceIndex] = '';
    newDesktopAppIcons[destinationIndex] = '';
    setDeskAppNames(newDesktopAppNames);
    setDesktopAppIcons(newDesktopAppIcons);
  };

  const clickDeleteEvent = index => {
    setDeleteDesktopApp({
      id: desktopAppIds[index],
    });
  };

  let time1, time2;
  let timerID = 0;
  let desktopAppColmnArry = makeList(desktopAppColmn);
  let allAppColmnArry = makeList(allAppColmn);

  return (
    <DataContext.Provider>
      <EuiDragDropContext onDragEnd={onDragEnd} /*onDragUpdate={onDragUpdate}*/>
        <div
          style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
          id="allPanel"
        >
          {' '}
          <div
            ref={leftRef}
            style={{
              display: 'none',
              width: '30%',
              maxHeight: '100%',
              height: heightSync,
              backgroundColor: 'grey',
              //clear: 'both',
            }}
            id="leftPanel"
          >
            <span
              style={{
                float: 'left',
                width: '100%',
                height: 20,
                fontSize: 22,
                textAlign: 'center',
                margin: 10,
              }}
            >
              所有应用
            </span>
            <EuiFlexGroup alignItems="center">
              {allAppColmnArry.map(({ app }, colmnIdx) => (
                <EuiFlexItem
                  style={{
                    width: kEachAllAppWidth - 10,
                    height: kEachAllAppHeight - 10,
                  }}
                >
                  <EuiDroppable
                    droppableId={`DROPPABLE_AREA_ALLAPP_${colmnIdx}`}
                    cloneDraggables={true}
                    spacing="l"
                    grow
                  >
                    {appList.map(({ content, id }, idx) => (
                      <EuiDraggable
                        key={(idx * allAppColmn + colmnIdx + kTotalAppOffset).toString()}
                        index={idx * allAppColmn + colmnIdx + kTotalAppOffset}
                        draggableId={(idx * allAppColmn + colmnIdx + kTotalAppOffset).toString()}
                        spacing="l"
                      >
                        <div
                          onClick={e => {
                            window.open(`${appUrls[idx * allAppColmn + colmnIdx]}`, '_blank');
                            // window.location.href = `${appUrls[idx * allAppColmn + colmnIdx]}`;
                          }}
                        >
                          <EuiPanel
                            style={{
                              visibility: `${
                                appIds[idx * allAppColmn + colmnIdx] === undefined
                                  ? 'hidden'
                                  : 'visible'
                              }`,
                            }}
                            paddingSize="s"
                          >
                            <EuiFlexGroup
                              style={{ display: 'inline' }}
                              gutterSize="none"
                              alignItems="center"
                            >
                              <EuiIcon
                                style={{
                                  width: '100%',
                                  height: 35,
                                  backgroundImage: `url(${
                                    process.env.PUBLIC_URL
                                  }/api/file/download?id=${
                                    appIcons[idx * allAppColmn + colmnIdx]
                                  })`,
                                  backgroundSize: '100% 100%',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPositionX: 'center',
                                }}
                              ></EuiIcon>
                              <EuiFlexItem
                                style={{
                                  fontSize: 14,
                                  textAlign: 'center',
                                  color: `${
                                    appDeskExis[idx * allAppColmn + colmnIdx] ? '#dddddd' : 'black'
                                  }`,
                                }}
                              >
                                {appNames[idx * allAppColmn + colmnIdx]}
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          </EuiPanel>
                        </div>
                      </EuiDraggable>
                    ))}
                  </EuiDroppable>
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          </div>
          <div
            ref={ref}
            style={{
              display: 'inline-block',
              maxHeight: '100%',
              height: heightSync,
              width: '100%',
              backgroundColor: '#eeeeee',
              float: 'right',
              //clear: 'both',
            }}
            id="rightPanel"
          >
            <button
              style={{
                float: 'left',
                marginLeft: 10,
                marginTop: 5,
                width: 46,
                height: 26,
                backgroundImage: `url(${right})`,
                backgroundSize: '100% 100%',
              }}
              id="switchButton"
              onClick={() => {
                updateVisible();
              }}
            ></button>
            <span
              style={{
                float: 'left',
                width: 150,
                height: 35,
                marginTop: 10,
                fontSize: 20,
              }}
            >
              桌面应用
            </span>
            <EuiFlexGroup alignItems="center" style={{ width: '100%', margin: kMargin }}>
              {desktopAppColmnArry.map(({ app }, colmnIdx) => {
                return (
                  <EuiFlexItem
                    style={{
                      width: kEachDesktopAppWidth - 20,
                      height: kEachDesktopAppHeight,
                      margin: 10,
                    }}
                  >
                    <EuiDroppable droppableId={`DROPPABLE_AREA_COPY_DESK_${colmnIdx}`} grow>
                      {desktopAppList.length / desktopAppColmn ? (
                        desktopAppList.map(({ content, id }, idx) => {
                          //console.log('---page--colmnIdx:', colmnIdx, idx);
                          return (
                            <EuiDraggable
                              key={(idx * desktopAppColmn + colmnIdx).toString()}
                              index={idx * desktopAppColmn + colmnIdx}
                              draggableId={(idx * desktopAppColmn + colmnIdx).toString()}
                              spacing="l"
                              isRemovable={isItemRemovable}
                            >
                              <div>
                                <EuiPanel
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    visibility: `${
                                      desktopAppIds[idx * desktopAppColmn + colmnIdx] === undefined
                                        ? 'hidden'
                                        : 'visible'
                                    }`,
                                  }}
                                >
                                  <EuiFlexGroup
                                    style={{ display: 'inline' }}
                                    gutterSize="none"
                                    alignItems="center"
                                  >
                                    <EuiFlexItem grow={false}>
                                      {isItemRemovable ? (
                                        <EuiIcon type="trash" color="danger" />
                                      ) : (
                                        <EuiButtonIcon
                                          iconType="cross"
                                          aria-label="Remove"
                                          onClick={() => {
                                            clickDeleteEvent(idx * desktopAppColmn + colmnIdx);
                                          }}
                                        />
                                      )}
                                    </EuiFlexItem>
                                    <EuiIcon
                                      style={{
                                        width: '100%',
                                        height: 40,
                                        backgroundImage: `url(${
                                          process.env.PUBLIC_URL
                                        }/api/file/download?id=${
                                          desktopAppIcons[idx * desktopAppColmn + colmnIdx]
                                        })`,
                                        backgroundSize: '100% 100%',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPositionX: 'center',
                                      }}
                                    ></EuiIcon>
                                    <EuiFlexItem
                                      style={{
                                        fontSize: 18,
                                        textAlign: 'center',
                                      }}
                                      onMouseDown={e => {
                                        time1 = moment().valueOf();
                                        timerID = window.setInterval(() => {
                                          if (
                                            desktopAppIds[idx * desktopAppColmn + colmnIdx] !==
                                            undefined
                                          )
                                            window.clearInterval(timerID);
                                        }, 500);
                                      }}
                                      onMouseUp={e => {
                                        time2 = moment().valueOf();
                                        let interval = time2 - time1;
                                        if (interval < 500) {
                                          if (
                                            desktopAppUrls[idx * desktopAppColmn + colmnIdx] !==
                                            undefined
                                          )
                                            window.open(
                                              `${desktopAppUrls[idx * desktopAppColmn + colmnIdx]}`,
                                              '_blank'
                                            );
                                          // window.location.href = `${
                                          //   desktopAppUrls[
                                          //     idx * desktopAppColmn + colmnIdx
                                          //   ]
                                          // }`;
                                        }
                                        window.clearInterval(timerID);
                                        timerID = 0;
                                      }}
                                    >
                                      {desktopAppNames[idx * desktopAppColmn + colmnIdx]}
                                    </EuiFlexItem>
                                  </EuiFlexGroup>
                                </EuiPanel>
                              </div>
                            </EuiDraggable>
                          );
                        })
                      ) : (
                        <EuiFlexGroup
                          justifyContent="spaceAround"
                          gutterSize="none"
                          style={{ height: '100%' }}
                        >
                          <EuiFlexItem grow={false}>
                            {colmnIdx === 0 ? '桌面应用拖到这里' : ''}
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      )}
                    </EuiDroppable>
                  </EuiFlexItem>
                );
              })}
            </EuiFlexGroup>
          </div>
        </div>
      </EuiDragDropContext>
    </DataContext.Provider>
  );
}

ReactDOM.render(
  <Layout>
    <Main />
  </Layout>,
  document.getElementById('desktopadmin')
);
