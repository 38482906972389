import useApps from './list';
import useDesktopApps from './desktopapp_list';
import usePostDesktopApp from './post';
import useDeleteDesktopApp from './delete';
import usePutDesktopApp from './put';

if (process.env.REACT_APP_DATA_ORIGIN === 'mock') {
  require('@/mock');
}

export { useApps, useDesktopApps, usePostDesktopApp, useDeleteDesktopApp, usePutDesktopApp };
