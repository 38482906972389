import Mock from 'mockjs';
import qs from 'qs';
import { file, test1, test2, test3, test4 } from '@/img';

export default Mock.mock(RegExp(/\/desktopadmins\/v2\/desktopapps(|\*)/), 'get', options => {
  console.log('     3mock---desktopApps-list:', options);
  let res = Mock.mock({
    rst: 200,
    data: [
      {
        id: 'id-1',
        name: 'Desk1',
        icon: `url(${test1})`,
      },
      {
        id: 'id-2',
        name: 'Desk2',
        icon: `url(${test2})`,
      },
      {
        id: 'id-3',
        name: 'Desk3',
        icon: `url(${test3})`,
      },
      {
        id: 'id-4',
        name: 'Desk4',
        icon: `url(${test4})`,
      },
      {
        id: 'id-5',
        name: 'Desk5',
        icon: `url(${file})`,
      },
      {
        id: 'id-6',
        name: 'Desk6',
        icon: `url(${test2})`,
      },
      {
        id: 'id-7',
        name: 'Desk7',
        icon: `url(${test3})`,
      },
      {
        id: 'id-8',
        name: 'Desk8',
        icon: `url(${test2})`,
      },
      {
        id: 'id-9',
        name: 'Desk9',
        icon: `url(${test4})`,
      },
      {
        id: 'id-10',
        name: 'Desk10',
        icon: `url(${test3})`,
      },
    ],

    msg: 'success',
  });
  const search = qs.parse(options.url.split('?')[1], { ignoreQueryPrefix: true });

  let count = res.data.length;

  //example:renturn data by options
  if (search.page && search.page_size) {
    res.data = res.data.slice((search.page - 1) * search.page_size, search.page * search.page_size);
  }
  if (search.id) count = 1;
  let data = { apps: res.data, total_count: count };
  res.data = data;
  return res;
});
