import close from './close.png';
import file from './file.png';
import left from './left.jpg';
import right from './right.jpg';
import test1 from './test1.png';
import test2 from './test2.png';
import test3 from './test3.png';
import test4 from './test4.png';

export { close, file, left, right, test1, test2, test3, test4 };
